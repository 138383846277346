import { createContext, useContext, useEffect, useReducer } from 'react'
import { AppService } from '../services/AppService'
import { AppInfo } from '../definitions/api.types'

interface AppProviderState {
  rightSidebar: boolean
  waffleFlags: string[]
  isAddServiceShown: boolean
  appInfo: AppInfo | null
  refetchTrigger: number
}

export const reducer = (state: AppProviderState, action: any) => {
  switch (action.type) {
    case 'showRightSidebar':
      return {
        ...state,
        rightSidebar: true,
      }

    case 'closeRightSidebar':
      return {
        ...state,
        rightSidebar: false,
      }

    case 'waffleFlags':
      return {
        ...state,
        waffleFlags: action.payload,
      }

    case 'showAddService':
      return {
        ...state,
        isAddServiceShown: action.payload,
      }

    case 'appInfo':
      return {
        ...state,
        appInfo: action.payload,
      }

    case 'refetchAppInfo':
      return {
        ...state,
        refetchTrigger: state.refetchTrigger + 1,
      }

    default:
      return state
  }
}

export const initialState = {
  rightSidebar: false,
  waffleFlags: [],
  isAddServiceShown: false,
  isGoogleMapsLoaded: false,
  appInfo: null,
  refetchTrigger: 0,
}

export const AppContext = createContext({
  state: initialState,
  dispatch: () => null,
})
AppContext.displayName = 'AppContext'

const app = new AppService()

function AppProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchAppInfo = async () => {
    const appInfo = await app.getAppInfo()
    dispatch({ type: 'appInfo', payload: appInfo.data })
  }

  useEffect(() => {
    fetchAppInfo()
  }, [])

  useEffect(() => {
    if (state.refetchTrigger) {
      fetchAppInfo()
    }
  }, [state.refetchTrigger])

  return <AppContext.Provider value={[state, dispatch]} {...props} />
}

const useApp = (): any => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error(`useApp must be used within a AppProvider`)
  }

  return context
}

export { AppProvider, useApp }
