import axios from "axios";
import { apiUrl, getAxiosRequestConfigWithHeaders } from "../misc/Utils";

export class AppService {
  getAppInfo() {
    return axios.get(
      apiUrl('/api/v1/app/info'),
      getAxiosRequestConfigWithHeaders()
    )
  }
}
